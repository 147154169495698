import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';

import {MatToolbarModule} from '@angular/material/toolbar';

import {MatSliderModule} from '@angular/material/slider';
import {MatButtonModule} from '@angular/material/button';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import {MatOptionModule} from '@angular/material/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatPaginatorModule} from '@angular/material/paginator';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RecordingComponent} from "./admin/recordings/recording.component";
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {AsyncPipe} from "@angular/common";
import {JwtInterceptor} from "./helpers/jwt.interceptor";
import {SignInComponent} from "./sign_in/sign_in.component";
import {WelcomeComponent} from "./outside/welcome.component";
import {SignUpComponent} from "./sign_up/sign_up.component";
import {HomeComponent} from "./home/home.component";
import {AdminComponent} from "./admin/admin.component";
import {RecordingDetailComponent} from "./admin/recordings/recording_detail.component";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {FeedbackDetailComponent} from "./admin/feedbacks/feedback_detail.component";
import {FeedbackComponent} from "./admin/feedbacks/feedback.component";
import {DatePipe} from '@angular/common';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {AddRecordingComponent} from "./admin/recordings/add-recording.component";

@NgModule({
  declarations: [
    AppComponent,
    RecordingComponent,
    SignInComponent,
    SignUpComponent,
    HomeComponent,
    AdminComponent,
    WelcomeComponent,
    FeedbackDetailComponent,
    FeedbackComponent,
    RecordingDetailComponent,
    AddRecordingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatInputModule,
    MatToolbarModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatSliderModule,
    MatButtonModule,
    MatSortModule,
    MatListModule,
    MatIconModule,
    MatTableModule,
    MatCardModule,
    MatSelectModule,
    MatOptionModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule
  ],
  providers: [
    AsyncPipe,
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

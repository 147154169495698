import {AfterViewInit, Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {FileChangeEvent} from "@angular/compiler-cli/src/perform_watch";


@Component({
  templateUrl: 'add-recording.html',
  styleUrls: ['add-recording.css']
})
export class AddRecordingComponent implements OnInit, AfterViewInit {

  public form!: FormGroup;


  constructor(public fb: FormBuilder, private http: HttpClient) {

  }


  ngAfterViewInit(): void {

  }

  ngOnInit() {
    this.form = this.fb.group({
      recognition: [''],
      translation: [''],
      confidence: [''],
      fromLanguage: [''],
      toLanguage: [''],
      reverseTranslation: [''],
      appTime: [''],
      user_id: [''],
      device_id: [''],
      ttsVersion: [''],
      kaldiVersion: [''],
      marianVersion: [''],
      w2vVersion: [''],
      asrRecordingFile: [null],
      ttsRecordingFile: [null]
    })
  }

  updateFile(event: Event, fileType: string) {
    console.log("uploadFile: " + event + ", " + fileType);


    if (event.currentTarget instanceof HTMLInputElement) {
      const element = event.currentTarget as HTMLInputElement;
      console.log("1")


      let fileList: FileList | null = element.files;
      console.log("2")
      if (fileList !== undefined && fileList !== null && fileList.length > 0) {
        let file = fileList[0];
        console.log("file: " + file)

        /*
        this.form?.get(fileType)?.setValue(
          file
        );

         */

        this.form.get(fileType)?.setValue(file)

        this.form.controls[fileType].patchValue([file]);
        this.form?.get(fileType)?.updateValueAndValidity()

        /*

        var reader = new FileReader();
        reader.onload = (e: any) => {
          console.log('Got here: ', e.target.result);

          let blob = new Blob([e.target.result], {
            type: 'audio/wav'
          })
          let arrayOfBlob = new Array<Blob>();
          arrayOfBlob.push(blob);

          let file2 = new File(arrayOfBlob, file?.name)

          this.form?.get(fileType)?.setValue(
            file2
          );
        }
        reader.readAsArrayBuffer(file);

         */


      }
    } else {
      console.log("not an instance")
    }
  }


  submitForm() {
    let formData: any = new FormData();
    if (this.form !== undefined) {
      Object.keys(this.form.controls).forEach(formControlName => {
        console.log("formControlName: " + formControlName);
        if (this.form !== undefined && this.form !== null) {
          let valz = this.form.get(formControlName)
          if (valz !== null) {
            console.log("valz.value: " + valz.value + "," + typeof (valz.value));
            if (valz.value instanceof Object) {
              let theFile = valz.value as File
              console.log("is file" + theFile.name)
              formData.append(formControlName, theFile, theFile.name);
            } else {
              console.log("not file")
              formData.append(formControlName, valz.value);
            }
          }
        }
      });
      this.http.post('https://dev2.mylanguage.me/auth/api/history', formData, {
        headers: {}
      })
        .subscribe(
          (response) => console.log(response),
          (error) => console.log(error)
        )
    }

  }

}

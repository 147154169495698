<div style="width: 400px; margin: 20px;">
  <mat-card style="width: 400px;">
    <mat-card-content>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <h2>Sign In</h2>
        <mat-error *ngIf="loginInvalid">
          The username and password were not recognized. Server responded with "{{error}}".
        </mat-error>
        <mat-form-field class="full-width-input" style="width: 100%;">
          <input matInput placeholder="Email" formControlName="username" required>
          <mat-error>
            Please provide a valid email address
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width-input" style="width: 100%;">
          <input matInput type="password" placeholder="Password" formControlName="password" required>
          <mat-error>
            Please provide a valid password
          </mat-error>
        </mat-form-field>
        <button mat-raised-button color="primary" style="margin-top: 40px;" [disabled]="loading">Login</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>

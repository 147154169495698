import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

import {DataService} from '../services/data.service';


@Component({
  selector: 'app-sign-up-component',
  templateUrl: 'sign_up.component.html'
})
export class SignUpComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';

  public signupInvalid: boolean = false;
  private formSubmitAttempt: boolean = false;
  private returnUrl: string | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService
  ) {
    /*
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
     */
    this.loginForm = this.formBuilder.group({});
  }

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group?.get('password')?.value;
    let confirmPass = group?.get('passwordc')?.value
    return pass === confirmPass ? null : {notSame: true}
  }

  ngOnInit() {


    this.loginForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      username: ['', [Validators.required, Validators.email, Validators.min(5)]],
      password: ['', [Validators.required, Validators.min(6)]],
      passwordc: ['', [Validators.required, Validators.min(6)]],
    },);


    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || '/';
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm?.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    this.signupInvalid = false;
    // stop here if form is invalid
    if (this.loginForm != null && this.loginForm.invalid) {
      return;
    }
    this.error = '';
    this.loading = true;
    if (this.f !== undefined) {
      this.dataService.signUp(this.f["name"]?.value, this.f["username"]?.value,
        this.f["password"]?.value, this.f["passwordc"]?.value).subscribe(
        (data: any) => {
          console.log(`here we go: ${JSON.stringify(data)}`);
          this.loading = false;
          setTimeout(() => {
            this.router.navigate(['sign-in']);
          }, 200);
        }, error => {
          this.signupInvalid = true;
          console.log('error' + error.message);
          this.error = error.message;
          this.loading = false;
        }, () => {
          console.log('complete');
          this.loading = false;
        }
      );
    }
  }
}

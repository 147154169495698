import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';

import {environment} from '../../environments/environment';
import {DataService} from "../services/data.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private dataService: DataService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('intercept');
    // add auth header with jwt if user is logged in and request is to the api url
    const isLoggedIn = this.dataService.token !== null;
    let url = request.url
    const isApiUrl = url.startsWith(environment.api2Url) || url.startsWith(environment.apiUrl);
    if (isLoggedIn && isApiUrl) {
      console.log(`bearer: ${this.dataService.token}`);
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.dataService.token}`
        }
      });
    }
    console.log('intercept');
    return next.handle(request);
  }
}

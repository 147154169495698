import {Component, OnDestroy, OnInit} from '@angular/core';


import {DataService} from "../../services/data.service";
import {ActivatedRoute, Data} from '@angular/router';
import {Recording} from "../../model/recording";
import {RecordingResponse} from "../../responses/recording.response";
import {FeedbacksResponse} from "../../responses/feedbacks.response";
import {Feedback} from "../../model/feedback";
import {FeedbackResponse} from "../../responses/feedback.response";


@Component({
  selector: 'app-feedback-details',
  templateUrl: './feedback_detail.component.html',
  styleUrls: ['./feedback_detail.component.css']
})
export class FeedbackDetailComponent implements OnInit, OnDestroy {

  feedback: Feedback | null = null;

  constructor(private route: ActivatedRoute, private dataService: DataService) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      console.log(params.get('id'));
      const id = Number(params.get('id'));
      this.dataService.getFeedbackById(id).subscribe((c: FeedbackResponse) => {
        console.log(c);
        this.feedback = c.data;
      });
    });
  }

  ngOnDestroy(): void {
  }


}

import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FeedbackDataSource} from './feedback.ds';
import {Router} from '@angular/router';
import {DataService} from '../../services/data.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {fromEvent, merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {User} from "../../model/user";

@Component({
  templateUrl: 'feedback.component.html',
  styleUrls: ['feedback.component.css']
})
export class FeedbackComponent implements OnInit, AfterViewInit {
  loading = false;
  currentUser: User | null = null;


  dataSource: FeedbackDataSource;
  totalCount: number;


  displayedColumns = ['appTime', 'platform', 'description', 'languageOne',
    'languageTwo', 'speechProblem',
    'translateProblem', 'textToSpeechProblem', 'detailsRecording', 'details'];

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  @ViewChild('input') input: ElementRef | null = null;
  @ViewChild('input2') input2: ElementRef | null = null;
  @ViewChild('input3') input3: ElementRef | null = null;
  @ViewChild('input4') input4: ElementRef | null = null;

  //@ViewChild('input5') input5: ElementRef | null = null;

  constructor(
    private router: Router,
    private dataService: DataService) {
    console.log('constructor');
    this.totalCount = 0;
    this.dataSource = new FeedbackDataSource(this.dataService);
    // Get first page for total count.
    this.dataService.getFeedbacks([''], 1, 15, '')
      .subscribe(data => {
        console.log('1st page');
        this.totalCount = data.total;
        // Now lets load datasource. (we have total count)
        const sort = 'appTime,desc';
        this.dataSource.loadItems([sort], 0, 15, '');
      });
  }

  ngOnInit() {
    console.log('ngOnInit');
    this.loading = true;
    this.loading = false;

  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit');
    if (this.input != null) {
      // server-side search
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          debounceTime(150),
          distinctUntilChanged(),
          tap(() => {
            if (this.paginator != null) {
              this.paginator.pageIndex = 0;
            }
            this.loadPage();
          })
        )
        .subscribe();
    } else {
      console.log('something null 1');
    }

    if (this.input2 != null) {
      // server-side search
      fromEvent(this.input2.nativeElement, 'keyup')
        .pipe(
          debounceTime(150),
          distinctUntilChanged(),
          tap(() => {
            if (this.paginator != null) {
              this.paginator.pageIndex = 0;
            }
            this.loadPage();
          })
        )
        .subscribe();
    } else {
      console.log('something null 1');
    }


    if (this.input3 != null) {
      // server-side search
      fromEvent(this.input3.nativeElement, 'keyup')
        .pipe(
          debounceTime(150),
          distinctUntilChanged(),
          tap(() => {
            if (this.paginator != null) {
              this.paginator.pageIndex = 0;
            }
            this.loadPage();
          })
        )
        .subscribe();
    } else {
      console.log('something null 1');
    }

    if (this.input4 != null) {
      // server-side search
      fromEvent(this.input4.nativeElement, 'keyup')
        .pipe(
          debounceTime(150),
          distinctUntilChanged(),
          tap(() => {
            if (this.paginator != null) {
              this.paginator.pageIndex = 0;
            }
            this.loadPage();
          })
        )
        .subscribe();
    } else {
      console.log('something null 1');
    }

    // reset the paginator after sorting
    if (this.sort != null) {
      this.sort.sortChange.subscribe(() => {
        if (this.paginator) {
          this.paginator.pageIndex = 0
        }
      });
    } else {
      console.log('something null 2');
    }

    if (this.sort != null && this.paginator != null) {
      // on sort or paginate events, load a new page
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          tap(() => {
            console.log("change")
            this.loadPage()
          })
        ).subscribe();
    } else {
      console.log('something null 3');
    }
  }

  loadPage() {
    console.log('loadPage');
    if (this.paginator != null) { // this.sort != null && && this.input != null
      console.log('passsed');
      const direction = this.sort?.direction;
      const active = this.sort?.active;
      const sort = active + ',' + direction;

      let filters = ''
      let input1Filter = this.input?.nativeElement.value;
      if (input1Filter !== null && input1Filter !== "") {
        filters = filters.concat(`&id=${input1Filter}`)
      }
      let input2Filter = this.input2?.nativeElement.value;
      if (input2Filter !== null && input2Filter !== "") {
        filters = filters.concat(`&platform=${input2Filter}`)
      }
      let input23Filter = this.input3?.nativeElement.value;
      if (input23Filter !== null && input23Filter !== "") {
        filters = filters.concat(`&description=${input23Filter}`)
      }
      let input4Filter = this.input4?.nativeElement.value;
      if (input4Filter !== null && input4Filter !== "") {
        filters = filters.concat(`&device_id=${input4Filter}`)
      }
      /*
      let input5Filter = this.input5?.nativeElement.value;
      if (input5Filter !== null && input5Filter !== "") {
        filters = filters.concat(`&speechProblem=${input5Filter}`)
      }

       */

      this.dataSource.loadItems(
        [sort],
        this.paginator.pageIndex, this.paginator.pageSize, filters);
    } else {
      console.log('something null 4');
    }
  }

  getFeedback(row: any) {
    console.log('getFeedback');
    console.log(`row: ${row.id}`);
  }

}

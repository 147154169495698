import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {finalize} from 'rxjs/operators';
import {DataService} from '../../services/data.service';
import {Recording} from '../../model/recording';
import {RecordingsResponse} from '../../responses/recordings.response';
import {FeedbacksResponse} from "../../responses/feedbacks.response";
import {Feedback} from "../../model/feedback";


export class FeedbackDataSource implements DataSource<Feedback> {

  private itemsSubject = new BehaviorSubject<Feedback[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public totalItems = 0;
  public loading = this.loadingSubject.asObservable();

  constructor(private dataService: DataService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<Feedback[]> {
    return this.itemsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.itemsSubject.complete();
    this.loadingSubject.complete();
  }

  loadItems(
    sorts: string[] = [], pageIndex = 0, pageSize = 15, filters: string) {
    console.log('loadItems  filters: ' + filters +
      ' sorts: ' + sorts + ' pageIndex: ' + pageIndex + ' pageSize: ' + pageSize);
    this.loadingSubject.next(true);

    this.dataService.getFeedbacks(sorts,
      pageIndex + 1, pageSize, filters).pipe(
      catchError((error) => of([])),
      finalize(() => this.loadingSubject.next(false))
    ).subscribe((response: FeedbacksResponse) => {
      console.log("loadItems response")
      console.log("right type")
      let data = response.data;


      this.totalItems = response.total

      if (data != null) {
        console.log("data not null")
        this.itemsSubject.next(data)
      } else {
        console.log("data  null")
      }
    });
  }
}

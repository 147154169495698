import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {DataService} from '../services/data.service';


@Component({
  selector: 'app-sign-in-component',
  templateUrl: 'sign_in.component.html'
})
export class SignInComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';

  public loginInvalid: boolean = false;
  private formSubmitAttempt: boolean = false;
  private returnUrl: string | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService
  ) {
    /*
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
     */

    this.loginForm = this.formBuilder.group({});
  }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]], // Validators.required, Validators.email
      password: ['', [Validators.required, Validators.min(6)]] // Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || '/';
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm?.controls;
  }

  onSubmit() {
    console.log("onSubmit");
    this.loginInvalid = false;
    this.submitted = true;
    this.loading = true;
    // stop here if form is invalid
    if (this.loginForm != null && this.loginForm.invalid) {
      console.log("early out");
      return;
    }
    this.error = '';
    this.loading = true;
    if (this.f !== undefined) {
      this.dataService.signIn(this.f["username"]?.value, this.f["password"]?.value).subscribe(
        (data: any) => {
          console.log(`here we go: ${JSON.stringify(data)}`);
          this.loading = false;
          this.submitted = false;
          this.loginInvalid = false;
          this.loading = false;
          setTimeout(() => {
            this.router.navigate(['home']).then(r => {
              console.log("routed");
            });
          }, 200);
        }, error => {
          console.log('HTTP Error', error.error);
          let errorString = ""
          if (error !== null && error !== undefined) {
            errorString = error.message
          }
          console.log('error' + errorString);
          this.error = errorString;
          this.loading = false;
          this.loginInvalid = true;
        }, () => {
          console.log('complete');
          this.loading = false;
        }
      );
    }
  }
}

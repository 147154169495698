<mat-toolbar color="primary" class="mat-elevation-z6">
  <mat-toolbar-row>
    <a routerLink="/" style="font-weight: bold;"><img src="assets/logo.png" style="height:44px;"></a>
    <a mat-button routerLink="/admin" *ngIf="isAdmin">Admin</a>
    <span class="flexExpand"></span>
    <a mat-button routerLink="/sign-up" *ngIf="!currentUser">Sign Up</a>
    <a mat-button routerLink="/sign-in" *ngIf="!currentUser">Sign In</a>
    <!--<a mat-button routerLink="/settings" *ngIf="currentUser">Settings</a>-->
    <a mat-button (click)="logout()" *ngIf="currentUser">Sign Out</a>
  </mat-toolbar-row>
</mat-toolbar>
<!-- main app container -->
<div class="container">
  <router-outlet></router-outlet>
</div>

<form [formGroup]="form" enctype="multipart/form-data" (ngSubmit)="submitForm()">

  <h3>Angular Form</h3>
  <div class="form-group">
    <label for="recognition">Recognition:</label>
    <input formControlName="recognition" type="text" id="recognition" name="recognition" placeholder="recognition">
  </div>

  <div class="form-group">
    <label for="translation">Translation:</label>
    <input formControlName="translation" type="text" id="translation" name="translation" placeholder="translation">
  </div>

  <div class="form-group">
    <label for="fromLanguage">fromLanguage:</label>
    <input formControlName="fromLanguage" type="text" id="fromLanguage" name="fromLanguage" placeholder="fromLanguage">
  </div>

  <div class="form-group">
    <label for="confidence">confidence:</label>
    <input formControlName="confidence" type="text" id="confidence" name="confidence" placeholder="confidence">
  </div>


  <div class="form-group">
    <label for="appTime">appTime:</label>
    <input formControlName="appTime" type="text" id="appTime" name="appTime" placeholder="appTime">
  </div>

  <div class="form-group">
    <label for="asrRecordingFile">asrRecordingFile:</label>
    <input formControlName="asrRecordingFile" type="file" id="asrRecordingFile" name="asrRecordingFile"
           (change)="updateFile($event,'asrRecordingFile')">
  </div>


  <div class="form-group">
    <label for="toLanguage">toLanguage:</label>
    <input formControlName="toLanguage" type="text" id="toLanguage" name="toLanguage" placeholder="toLanguage">
  </div>

  <div class="form-group">
    <label for="ttsRecordingFile">ttsRecordingFile:</label>
    <input formControlName="ttsRecordingFile" type="file" id="ttsRecordingFile" name="ttsRecordingFile"
           (change)="updateFile($event, 'ttsRecordingFile')">
  </div>

  <div class="form-group">
    <label for="reverseTranslation">reverseTranslation:</label>
    <input formControlName="reverseTranslation" type="text" id="reverseTranslation" name="reverseTranslation"
           placeholder="reverseTranslation">
  </div>


  <div class="form-group">
    <label for="user_id">user_id:</label>
    <input formControlName="user_id" type="text" id="user_id" name="user_id"
           placeholder="user_id">
  </div>

  <div class="form-group">
    <label for="device_id">device_id:</label>
    <input formControlName="device_id" type="text" id="device_id" name="device_id"
           placeholder="device_id">
  </div>

  <div class="form-group">
    <label for="kaldiVersion">kaldiVersion:</label>
    <input formControlName="kaldiVersion" type="text" id="kaldiVersion" name="kaldiVersion"
           placeholder="kaldiVersion">
  </div>

  <div class="form-group">
    <label for="marianVersion">marianVersion:</label>
    <input formControlName="marianVersion" type="text" id="marianVersion" name="marianVersion"
           placeholder="marianVersion">
  </div>

  <div class="form-group">
    <label for="w2vVersion">w2vVersion:</label>
    <input formControlName="w2vVersion" type="text" id="w2vVersion" name="w2vVersion"
           placeholder="w2vVersion">
  </div>

  <div class="form-group">
    <label for="ttsVersion">ttsVersion:</label>
    <input formControlName="ttsVersion" type="text" id="ttsVersion" name="ttsVersion"
           placeholder="ttsVersion">
  </div>
  <div class="form-group">
    <button>Submit</button>
  </div>
</form>

<h3>Old School Form</h3>
<form enctype="multipart/form-data" method="post" action="https://dev2.mylanguage.me/auth/api/history">
  <input type="text" id="recognition2" name="recognition" placeholder="recognition">
  <input type="text" id="translation2" name="translation" placeholder="translation">
  <input type="text" id="confidence2" name="confidence" placeholder="confidence">
  <input type="text" id="appTime2" name="appTime" placeholder="appTime">
  <input type="file" id="asrRecordingFil2e" name="asrRecordingFile">
  <input type="text" id="toLanguage2" name="toLanguage" placeholder="toLanguage">
  <input type="file" id="ttsRecordingFile2" name="ttsRecordingFile">
  <input type="text" id="reverseTranslation2" name="reverseTranslation" placeholder="reverseTranslation">
  <input type="text" id="user_id2" name="user_id" placeholder="user_id">
  <input type="text" id="device_id2" name="device_id" placeholder="device_id">
  <input type="text" id="kaldiVersion2" name="kaldiVersion" placeholder="kaldiVersion">
  <input type="text" id="marianVersion2" name="marianVersion" placeholder="marianVersion">
  <input type="text" id="w2vVersion2" name="w2vVersion" placeholder="w2vVersion">
  <input type="text" id="ttsVersion2" name="ttsVersion" placeholder="ttsVersion">
  <input type="submit" value="Submit">
</form>

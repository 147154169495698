import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';


import {environment} from '../../environments/environment';
import {User} from "../model/user";
import {RecordingsResponse} from "../responses/recordings.response";
import {LoginResponse} from "../responses/logn";
import {SignUpResponse} from "../responses/signup.response";
import {FeedbacksResponse} from "../responses/feedbacks.response";
import {Feedback} from "../model/feedback";
import {RecordingResponse} from "../responses/recording.response";
import {FeedbackResponse} from "../responses/feedback.response";


@Injectable({providedIn: 'root'})
export class DataService {
    public currentUserSubject: BehaviorSubject<User | null>;

    token: string | null = null;

    expires: number = 0;

    constructor(private http: HttpClient) {
        let storageValue = localStorage.getItem('user');
        console.log("user: " + storageValue);
        if (storageValue != null) {
            const currentUser = JSON.parse(storageValue);
            this.currentUserSubject = new BehaviorSubject<User | null>(currentUser);
        } else {
            this.currentUserSubject = new BehaviorSubject<User | null>(null);
        }
        let storageValue2 = localStorage.getItem('token');
        this.token = storageValue2;
        console.log("token: " + storageValue2);
        let storageValue3 = localStorage.getItem('expires');
        this.expires = Number(storageValue3);
        console.log("expires: " + storageValue3);
    }

    signIn(userName: string, password: string) {
        console.log(`logging in ${userName}`);
        const password2 = password;
        let body = new URLSearchParams();
        body.set('email', userName);
        body.set('password', password2);
        // {userName, password2})
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        return this.http.post<LoginResponse>(`${environment.apiUrl}/login`, body, options)
            .pipe(map((loginResponse: LoginResponse) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                const user = loginResponse.user;
                if (user !== null) {
                    localStorage.setItem('user', JSON.stringify(loginResponse.user));
                    this.currentUserSubject?.next(user);
                    console.log('next called');
                }
                if (loginResponse.access_token !== null) {
                    let expires_in = loginResponse.expires_in
                    console.log("expires_in: " + expires_in)
                    let expires = ((new Date()).getTime() / 1000) + expires_in;
                    localStorage.setItem('token', loginResponse.access_token);
                    localStorage.setItem('expires', expires.toString());
                    this.token = loginResponse.access_token
                }
                return true;
            }));
    }

    signUp(name: string, email: string, password: string, passwordc: string) {
        let body = new URLSearchParams();
        body.set('name', name);
        body.set('email', email);
        body.set('password', password);
        body.set('password_confirmation', passwordc);
        let options = {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        };
        return this.http.post<SignUpResponse>(`${environment.apiUrl}/register`, body, options)
            .pipe(map((signUpResponse: SignUpResponse) => {
                return true;
            }));

    }


    logout() {
        console.log(`log out`);
        // remove user from local storage to log user out
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('expires');
        this.token = null
        this.currentUserSubject?.next(null);
    }

    getUsers(filter: string, filterBy: string, sorts: string[], pageIndex: number, pageSize: number): Observable<any> {
        let sortsPostFix = '';
        sorts.forEach((i) => {
            sortsPostFix += '&sort=' + i;
        });
        return this.http.get<RecordingsResponse>(
            environment.api2Url +
            '/usersp?' +
            'filter=' + filter +
            '&filterBy=' + filterBy +
            '&page=' + pageIndex +
            '&size=' + pageSize +
            '' + sortsPostFix);
    }

    public getUserById(id: number): Observable<User> {
        return this.http.get<User>(`${environment.api2Url}/user/${id}`);
    }


    getRecordings(sorts: string[], pageIndex: number, pageSize: number, filters: string): Observable<any> {
        let sortsPostFix = '';
        sorts.forEach((i) => {
            sortsPostFix += '&sort=' + i;
        });
        return this.http.get<RecordingsResponse>(
            environment.api2Url +
            '/recordingsp?' +
            'page=' + pageIndex +
            '&size=' + pageSize +
            filters +
            '' + sortsPostFix);
    }

    public getRecordingById(id: number): Observable<RecordingResponse> {
        return this.http.get<RecordingResponse>(`${environment.api2Url}/recordings/${id}`);
    }


    getFeedbacks(sorts: string[], pageIndex: number, pageSize: number, filters: string): Observable<any> {
        let sortsPostFix = '';
        sorts.forEach((i) => {
            sortsPostFix += '&sort=' + i;
        });
        return this.http.get<FeedbacksResponse>(
            environment.api2Url +
            '/feedbackp?' +
            'page=' + pageIndex +
            '&size=' + pageSize +
            filters +
            '' + sortsPostFix);
    }

    public getFeedbackById(id: number): Observable<FeedbackResponse> {
        return this.http.get<FeedbackResponse>(`${environment.api2Url}/feedback/${id}`);
    }
}

<h3>Feedback Item Details</h3>


<div class="row">
  <div class="header">languageOne</div>
  <div id="languageOne" class="value">{{feedback?.languageOne}}</div>
</div>
<div class="row">
  <div class="header">languageTwo</div>
  <div id="languageTwo" class="value">{{feedback?.languageTwo}}</div>
</div>
<div class="row">
  <div class="header">speechText</div>
  <div id="speechText" class="value">{{feedback?.speechText}}</div>
</div>
<div class="row">
  <div class="header">translationText</div>
  <div id="translationText" class="value">{{feedback?.translationText}}</div>
</div>
<div class="row">
  <div class="header">speechProblem</div>
  <div id="speechProblem" class="value">{{feedback?.speechProblem}}</div>
</div>
<div class="row">
  <div class="header">textToSpeechProblem</div>
  <div id="textToSpeechProblem" class="value">{{feedback?.textToSpeechProblem}}</div>
</div>
<div class="row">
  <div class="header">translateProblem</div>
  <div id="translateProblem" class="value">{{feedback?.translateProblem}}</div>
</div>
<div class="row">
  <div class="header">otherProblem</div>
  <div id="otherProblem" class="value">{{feedback?.otherProblem}}</div>
</div>
<div class="row">
  <div class="header">slowProblem</div>
  <div id="slowProblem" class="value">{{feedback?.slowProblem}}</div>
</div>
<div class="row">
  <div class="header">recording_id</div>
  <div id="recording_id" class="value">{{feedback?.recording_id}}</div>
</div>
<div class="row">
  <div class="header">platform</div>
  <div id="platform" class="value">{{feedback?.platform}}</div>
</div>
<div class="row">
  <div class="header">osVersion</div>
  <div id="osVersion" class="value">{{feedback?.osVersion}}</div>
</div>
<div class="row">
  <div class="header">user_id</div>
  <div id="user_id" class="value">{{feedback?.user_id}}</div>
</div>
<div class="row">
  <div class="header">device_id</div>
  <div id="device_id" class="value">{{feedback?.device_id}}</div>
</div>
<div class="row">
  <div class="header">description</div>
  <div id="description" class="value">{{feedback?.description}}</div>
</div>
<div class="row">
  <div class="header">appTime</div>
  <div id="appTime" class="value">{{(feedback?.appTime || 0) * 1000  | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
</div>
<div class="row">
  <div class="header">appVersion</div>
  <div id="appVersion" class="value">{{feedback?.appVersion}}</div>
</div>
<div class="row">
  <div class="header">created_at</div>
  <div id="created_at" class="value">{{feedback?.created_at}}</div>
</div>
<div class="row">
  <div class="header">updated_at</div>
  <div id="updated_at" class="value">{{feedback?.updated_at}}</div>
</div>

import {AfterViewInit, Component, OnInit} from "@angular/core";

@Component({
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

}

<div style="overflow-y: scroll; height:100%">
  <h3>Recording Details</h3>


  <br style="clear: both;"/>

  <h4 style="color: #00AAFF;">User/Device Info</h4>

  <div style="float: left; width: 100%">
    <div class="row">
      <div class="header">User ID</div>
      <div class="value">{{recording?.user_id}}</div>
    </div>
    <div class="row">
      <div class="header">Device ID</div>
      <div class="value">{{recording?.device_id}}</div>
    </div>
    <div class="row">
      <div class="header">App Time</div>
      <div class="value">{{(recording?.appTime || 0) * 1000 | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
    </div>
  </div>
  <br style="clear: both;"/>

  <h4 style="color: #00AAFF;">Server Info</h4>

  <div style="float: left; width: 100%">
    <div class="row">
      <div class="header">created_at</div>
      <div id="created_at" class="value">{{recording?.created_at}}</div>
    </div>
    <div class="row">
      <div class="header">updated_at</div>
      <div id="updated_at" class="value">{{recording?.updated_at}}</div>
    </div>
  </div>
  <br style="clear: both;"/>


  <h4 style="color: #00AAFF;">Speech Recognition</h4>


  <div style="float: left; width: 100%">

    <div class="row">
      <div class="header">Spoken Language</div>
      <div class="value">{{recording?.fromLanguage}}</div>
    </div>
    <div class="row">
      <div class="header">Recognized Text</div>
      <div class="value">{{recording?.recognition}}</div>
    </div>
    <div class="row">
      <div class="header">Average Confidence</div>
      <div class="value">{{recording?.confidence}}</div>
    </div>
    <div class="row">
      <div class="header">Server File Location</div>
      <div class="value">{{recording?.serverFilePath}}</div>
    </div>

    <div class="row">
      <div class="header">Kaldi Version</div>
      <div class="value">{{recording?.kaldiVersion}}</div>
    </div>

    <div class="row">
      <div class="header">Marian Version</div>
      <div class="value">{{recording?.marianVersion}}</div>
    </div>

    <div class="row">
      <div class="header">w2v Version</div>
      <div class="value">{{recording?.w2vVersion}}</div>
    </div>


    <div class="row">
      <div class="header">asrEngine</div>
      <div class="value">{{recording?.asrEngine}}</div>
    </div>
    <div class="row">
      <div class="header">asrVersion</div>
      <div class="value">{{recording?.asrVersion}}</div>
    </div>

    <div class="row">
      <div class="header">nmtEngine</div>
      <div class="value">{{recording?.nmtEngine}}</div>
    </div>
    <div class="row">
      <div class="header">nmtVersion</div>
      <div class="value">{{recording?.nmtVersion}}</div>
    </div>


    <div class="row">
      <div class="header">lidEngine</div>
      <div class="value">{{recording?.lidEngine}}</div>
    </div>
    <div class="row">
      <div class="header">lidVersion</div>
      <div class="value">{{recording?.lidVersion}}</div>
    </div>

    <div class="row">
      <div class="header">s2sEngine</div>
      <div class="value">{{recording?.s2sEngine}}</div>
    </div>
    <div class="row">
      <div class="header">s2sVersion</div>
      <div class="value">{{recording?.s2sVersion}}</div>
    </div>

    <div class="row">
      <div class="header">tts Engine</div>
      <div class="value">{{recording?.ttsEngine}}</div>
    </div>
    <div class="row">
      <div class="header">tts Version</div>
      <div class="value">{{recording?.ttsVersion}}</div>
    </div>

    <div class="row">
      <div class="header">platform</div>
      <div class="value">{{recording?.platform}}</div>
    </div>
    <div class="row">
      <div class="header">osVersion</div>
      <div class="value">{{recording?.osVersion}}</div>
    </div>

    <div class="row">
      <div class="header">appVersion</div>
      <div class="value">{{recording?.appVersion}}</div>
    </div>


    <div class="row">
      <button (click)="playPause()">{{playing ? "Pause" : "Play"}}</button>
    </div>

    <div class="row">
      <div id="waveform" style="width:650px"></div>
      <div id="wave-timeline" style="width:650px"></div>
    </div>

    <div class="row">
      <a href="{{audioUrl}}" download>Download Recording</a>
    </div>

  </div>

  <br style="clear: both;"/>
  <h4 style="margin-top: 80px; color: #00AAFF;">Translation</h4>

  <div style="float: left; width: 100%">
    <div class="row">
      <div class="header">From Language</div>
      <div class="value">{{recording?.fromLanguage}}</div>
    </div>
    <div class="row">
      <div class="header">To Language</div>
      <div class="value">{{recording?.toLanguage}}</div>
    </div>
    <div class="row">
      <div class="header">Translated Text</div>
      <div class="value">{{recording?.translation}}</div>
    </div>
    <div class="row">
      <div class="header">Reverse Translated Text</div>
      <div class="value">{{recording?.reverseTranslation}}</div>
    </div>
  </div>

  <br style="clear: both;"/>
  <h4 style="margin-top: 80px; color: #00AAFF;">Text to Speech</h4>

  <div style="float: left; width: 100%">
    <div class="row">
      <div class="header">Language</div>
      <div class="value">{{recording?.toLanguage}}</div>
    </div>

    <div class="row">
      <div class="header">Translated Text</div>
      <div class="value">{{recording?.translation}}</div>
    </div>
    <div class="row">
      <div class="header">TTS File Location</div>
      <div class="value">{{recording?.ttsFileLocation}}</div>
    </div>

    <div class="row">
      <button (click)="playPause2()">{{playing2 ? "Pause" : "Play"}}</button>
    </div>
    <div class="row">
      <div id="waveform2" style="width:650px"></div>
      <div id="wave-timeline2" style="width:650px"></div>
    </div>
    <div class="row">
      <a href="{{audioUrl2}}" download>Download TTS</a>
    </div>
  </div>


  <br style="clear: both;"/>

  <div style="width: 100%; float: left; height: 80px;">&nbsp;</div>
</div>




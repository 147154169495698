import {Component, OnInit} from '@angular/core';


import {FormBuilder, Validators} from '@angular/forms';
import {DataService} from '../services/data.service';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {


  constructor(private dataService: DataService, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
  }


}

import {AfterViewInit, Component, OnDestroy, OnInit} from "@angular/core";
import {User} from "../model/user";
import {DataService} from "../services/data.service";
import {Router} from "@angular/router";
import {SubscriptionLike} from "rxjs";

@Component({
  templateUrl: 'welcome.component.html',
  styleUrls: ['welcome.component.css']
})
export class WelcomeComponent implements OnInit, AfterViewInit, OnDestroy {
  currentUser: User | null = null;
  isAdmin: boolean = false;

  subscriptions: SubscriptionLike[] = [];

  constructor(
    private dataService: DataService,
    private router: Router) {

    let value = this.dataService.currentUserSubject?.getValue()
    if (value !== undefined) {
      this.currentUser = value;
    }
    this.subscriptions[0] = this.dataService.currentUserSubject?.asObservable().subscribe(x => {
      console.log('user changed' + x);
      this.currentUser = x;
      console.log(`here we go2: ${JSON.stringify(x)}`);
      if (x !== null && x !== undefined) {
        if (x.isAdmin !== undefined && x.isAdmin !== null) {
          this.isAdmin = x.isAdmin == 1;
        }
      }
    });
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    console.log('ngOnDestroy');
    this.subscriptions?.forEach((item) => {
      item.unsubscribe();
    });
  }

}

<div class="course" style="margin-bottom: 120px;">
  <h3>Recordings</h3>

  <h5>Actions</h5>

  <button [routerLink]="'/admin/recordings/add'">Add Recording</button>

  <h5>Filters</h5>
  <mat-form-field>
    <input matInput placeholder="ID" #input>
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="recognized text" #input2>
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="from lang" #input3>
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="to lang" #input4>
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="device id" #input5>
  </mat-form-field>


  <mat-table class="boards-table mat-elevation-z8" style="width: 900px; margin: 20px" [dataSource]="dataSource"
             matSort matSortActive="appTime" matSortDirection="desc" matSortDisableClear>

    <ng-container matColumnDef="appTime">
      <mat-header-cell *matHeaderCellDef mat-sort-header>App Time</mat-header-cell><!--  mat-sort-header -->
      <mat-cell *matCellDef="let element">{{(element?.appTime || 0) * 1000 | date: 'MM-dd HH:mm'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="fromLanguage">
      <mat-header-cell *matHeaderCellDef>fromLanguage</mat-header-cell>
      <mat-cell
        *matCellDef="let element">{{element.fromLanguage}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="recognition">
      <mat-header-cell *matHeaderCellDef>recognition</mat-header-cell>
      <mat-cell
        *matCellDef="let element">{{element.recognition}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="toLanguage">
      <mat-header-cell *matHeaderCellDef>toLanguage</mat-header-cell>
      <mat-cell
        *matCellDef="let element">{{element.toLanguage}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="translation">
      <mat-header-cell *matHeaderCellDef>translation</mat-header-cell>
      <mat-cell
        *matCellDef="let element">{{element.translation}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="device_id">
      <mat-header-cell *matHeaderCellDef>device_id</mat-header-cell>
      <mat-cell
        *matCellDef="let element">{{element.device_id}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="details">
      <mat-header-cell *matHeaderCellDef>details</mat-header-cell>
      <mat-cell
        *matCellDef="let element"><a mat-button [routerLink]="['/admin/recording', element.id, 'details']">Details</a>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns" (click)="getRecord(row)"></mat-row>

  </mat-table>
  <mat-paginator [length]="dataSource.totalItems"
                 [hidePageSize]="true"
                 [pageSize]="15" style="width: 900px; margin-left: 20px; margin-right: 20px;"
                 [pageSizeOptions]="[15, 30, 60]">

  </mat-paginator>

  <div class="spinner-container" *ngIf="dataSource.loading | async">
    <mat-spinner></mat-spinner>
  </div>

</div>

<div class="course" style="margin-bottom: 120px;">

  <h3>Feedback</h3>


  <mat-form-field>
    <input matInput placeholder="ID" #input>
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="platform" #input2>
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="description" #input3>
  </mat-form-field>

  <mat-form-field>
    <input matInput placeholder="device_id" #input4>
  </mat-form-field>

  <!--
  <mat-form-field>
    <label>Spoken Translation Problem
      <mat-checkbox #input5></mat-checkbox>
    </label>
  </mat-form-field>
  -->


  <mat-table class="boards-table mat-elevation-z8" style="width: 900px; margin: 20px" [dataSource]="dataSource"
             matSort matSortActive="appTime" matSortDirection="desc" matSortDisableClear>

    <ng-container matColumnDef="appTime">
      <mat-header-cell *matHeaderCellDef mat-sort-header>App Time</mat-header-cell><!--  mat-sort-header -->
      <mat-cell *matCellDef="let element">{{(element?.appTime || 0) * 1000  | date: 'MM-dd HH:mm'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="platform">
      <mat-header-cell *matHeaderCellDef>platform</mat-header-cell>
      <mat-cell
        *matCellDef="let element">{{element.platform}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="languageOne">
      <mat-header-cell *matHeaderCellDef>L1</mat-header-cell>
      <mat-cell
        *matCellDef="let element">{{element.languageOne}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="languageTwo">
      <mat-header-cell *matHeaderCellDef>L2</mat-header-cell>
      <mat-cell
        *matCellDef="let element">{{element.languageTwo}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>description</mat-header-cell>
      <mat-cell
        *matCellDef="let element">{{element.description}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="speechProblem">
      <mat-header-cell *matHeaderCellDef>SP</mat-header-cell>
      <mat-cell
        *matCellDef="let element">{{element.speechProblem}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="translateProblem">
      <mat-header-cell *matHeaderCellDef>TP</mat-header-cell>
      <mat-cell
        *matCellDef="let element">{{element.translateProblem}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="textToSpeechProblem">
      <mat-header-cell *matHeaderCellDef>TTSP</mat-header-cell>
      <mat-cell
        *matCellDef="let element">{{element.textToSpeechProblem}}
      </mat-cell>
    </ng-container>

    <!--
    <ng-container matColumnDef="slowProblem">
      <mat-header-cell *matHeaderCellDef>SLP</mat-header-cell>
      <mat-cell
        *matCellDef="let element">{{element.slowProblem}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="otherProblem">
      <mat-header-cell *matHeaderCellDef>OP</mat-header-cell>
      <mat-cell
        *matCellDef="let element">{{element.otherProblem}}
      </mat-cell>
    </ng-container>
    -->

    <ng-container matColumnDef="detailsRecording">
      <mat-header-cell *matHeaderCellDef>detailsRecording</mat-header-cell>
      <mat-cell
        *matCellDef="let element"><a *ngIf="element.recording_id" mat-button [routerLink]="['/admin/recording', element.recording_id, 'details']">Recording</a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="details">
      <mat-header-cell *matHeaderCellDef>details</mat-header-cell>
      <mat-cell
        *matCellDef="let element"><a  mat-button [routerLink]="['/admin/feedbacks', element.id, 'details']">Details</a>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns" (click)="getFeedback(row)"></mat-row>

  </mat-table>

  <mat-paginator [length]="dataSource.totalItems"
                 [hidePageSize]="true"
                 [pageSize]="15" style="width: 900px; margin-left: 20px; margin-right: 20px;"
                 [pageSizeOptions]="[15, 30, 60]">

  </mat-paginator>

</div>

import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {RecordingComponent} from "./admin/recordings/recording.component";
import {WelcomeComponent} from "./outside/welcome.component";
import {SignInComponent} from "./sign_in/sign_in.component";
import {SignUpComponent} from "./sign_up/sign_up.component";
import {HomeComponent} from "./home/home.component";
import {AdminComponent} from "./admin/admin.component";
import {RecordingDetailComponent} from "./admin/recordings/recording_detail.component";
import {FeedbackComponent} from "./admin/feedbacks/feedback.component";
import {FeedbackDetailComponent} from "./admin/feedbacks/feedback_detail.component";
import {AddRecordingComponent} from "./admin/recordings/add-recording.component";


const routes: Routes = [

  {path: '', component: WelcomeComponent}, //, canActivate: [AuthGuard]},
  {path: 'home', component: HomeComponent}, //, canActivate: [AuthGuard]},
  // {path: 'login', component: LoginComponent},
  {path: 'sign-in', component: SignInComponent},
  {path: 'sign-up', component: SignUpComponent},


  {
    path: 'admin',
    component: AdminComponent,

    children: [
      {path: 'recordings', component: RecordingComponent, pathMatch: 'full'},
      {path: 'recordings/add', component: AddRecordingComponent, pathMatch: 'full'},
      {path: 'recording/:id/details', component: RecordingDetailComponent, pathMatch: 'full'},
      {path: 'feedbacks', component: FeedbackComponent, pathMatch: 'full'},
      {path: 'feedbacks/:id/details', component: FeedbackDetailComponent, pathMatch: 'full'},
      /*
      {path: 'users', component: UsersComponent, pathMatch: 'full'},
      {path: 'files', component: FilesComponent, pathMatch: 'full'},
      {path: 'recording/:id/details', component: RecordingDetailComponent, pathMatch: 'full'},
      {path: 'file/:id/details', component: FileDetailComponent, pathMatch: 'full'},
      {path: 'user/:id/details', component: UserDetailComponent, pathMatch: 'full'},
      {path: 'language', component: LangaugeComponent, pathMatch: 'full'},

       */
      {path: '', redirectTo: 'admin', pathMatch: 'full'},
    ]
  },


  // otherwise redirect to home
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import {Component, OnDestroy} from '@angular/core';
import {User} from "./model/user";

import {DataService} from './services/data.service';
import {Router} from '@angular/router';
import {SubscriptionLike} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css']
})
export class AppComponent implements OnDestroy {
  title = 'myLanguage';
  currentUser: User | null = null;
  isAdmin: boolean = false;

  subscriptions2: SubscriptionLike[] = [];

  constructor(
    private dataService: DataService,
    private router: Router) {

    let value = this.dataService.currentUserSubject?.getValue()
    if (value !== undefined) {
      this.currentUser = value;
    }
    this.subscriptions2[0] = this.dataService.currentUserSubject?.asObservable().subscribe(x => {
      console.log('user changed' + x);
      this.currentUser = x;
      console.log(`here we go2: ${JSON.stringify(x)}`);
      if (x !== null && x !== undefined) {
        if (x.isAdmin !== undefined && x.isAdmin !== null) {
          this.isAdmin = x.isAdmin == 1;
        }
      }
    });
  }

  logout() {
    console.log('logout');
    this.dataService.logout();
    this.isAdmin = false;
    const value = this.router.navigate(['/welcome']);
    console.log(`nav: ${value}`);
  }

  ngOnDestroy() {
    console.log('ngOnDestroy');
    this.subscriptions2.forEach((item) => {
      item.unsubscribe();
    });
  }

}

<div
  style="position: fixed; left: 0;  width: 180px; height: calc(100% - 64px);  background-color: #f1f1f1;"
  class="mat-elevation-z4">
  <mat-nav-list>
    <div mat-list-item class="mat-h3" style="color: #673ab7; font-weight: bold; margin-left: 16px;">Admin</div>
    <a mat-list-item routerLink="/admin/recordings">Recordings</a>
    <a mat-list-item routerLink="/admin/feedbacks">Feedback</a>
    <!--
    <a mat-list-item routerLink="/admin/users">Users</a>
    <a mat-list-item routerLink="/admin/files">Model Files</a>
    <a mat-list-item routerLink="/admin/language">Language</a>
    -->
  </mat-nav-list>
</div>
<div
  style="position: fixed; padding: 30px;left: 180px; width: calc(100% - 180px); height: calc(100% - 64px); overflow-y: scroll;">
  <router-outlet></router-outlet>
</div>

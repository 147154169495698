import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';


import {DataService} from "../../services/data.service";
import {ActivatedRoute, Data} from '@angular/router';
import {Recording} from "../../model/recording";
import {RecordingResponse} from "../../responses/recording.response";

import WaveSurfer from 'wavesurfer.js';
import SpectrogramPlugin from 'wavesurfer.js/src/plugin/spectrogram';
// import WaveSurfer = require('wavesurfer.js');
// import SpectrogramPlugin from 'wavesurfer.js/src/plugin/spectrogram';

@Component({
  selector: 'app-recording-details',
  templateUrl: './recording_detail.component.html',
  styleUrls: ['./recording_detail.component.css']
})
export class RecordingDetailComponent implements OnInit, OnDestroy, AfterViewInit {

  wave: WaveSurfer | null = null;
  wave2: WaveSurfer | null = null;

  recording: Recording | null = null;

  audioUrl: string | null = null;
  audioUrl2: string | null = null;

  playing: boolean = false;

  playing2: boolean = false;

  constructor(private route: ActivatedRoute, private dataService: DataService) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      console.log(params.get('id'));
      const id = Number(params.get('id'));
      this.dataService.getRecordingById(id).subscribe((c: RecordingResponse) => {
        console.log(c);
        this.recording = c.data;

        if (this.dataService.token !== null) {
          this.audioUrl = "https://dev2.mylanguage.me/auth/api2/recordingD/" +
            this.recording?.id + "?token=" + encodeURIComponent(this.dataService.token);
          this.audioUrl2 = "https://dev2.mylanguage.me/auth/api2/recordingsTts/" +
            this.recording?.id + "?token=" + encodeURIComponent(this.dataService.token);
        }

        this.displayWaveform();

      });
    });
  }


  displayWaveform() {
    this.wave = WaveSurfer.create({
      container: '#waveform',
      waveColor: 'violet',
      progressColor: 'purple',
      plugins: [
        SpectrogramPlugin.create({
          wavesurfer: this.wave,
          container: '#wave-timeline',
          labels: true
        })
      ]
    });

    if (this.audioUrl !== null) {
      this.wave.load(this.audioUrl);
    }
    this.wave.on('finish', () => {
      console.log("finish")
      this.playing = false
    });
    this.wave2 = WaveSurfer.create({
      container: '#waveform2',
      waveColor: 'violet',
      progressColor: 'purple',
      plugins: [
        SpectrogramPlugin.create({
          wavesurfer: this.wave2,
          container: '#wave-timeline2',
          labels: true
        })
      ]
    });

    if (this.audioUrl2 !== null) {
      this.wave2.load(this.audioUrl2);
    }
    this.wave2.on('finish', () => {
      console.log("finish")
      this.playing2 = false
    });
    //this.wave.on('ready', () => {
    // this.wave?.play();
    //});
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit() {

  }

  playPause() {
    if (this.wave !== null) {
      if (this.wave.isPlaying()) {
        this.wave.pause();
        this.playing = false;
      } else {
        this.wave.play();
        this.playing = true;
      }
    }
  }

  playPause2() {
    if (this.wave2 !== null) {
      if (this.wave2.isPlaying()) {
        this.wave2.pause();
        this.playing2 = false;
      } else {
        this.wave2.play();
        this.playing2 = true;
      }
    }
  }
}

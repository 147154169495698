<div style="width: 400px; margin: 20px;">
  <mat-card style="width: 400px;">
    <mat-card-content>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <h2>Sign Up</h2>
        <mat-error *ngIf="signupInvalid">
          The server didn't like your data for some reason. The server responded with "{{error}}".
        </mat-error>

        <mat-form-field class="full-width-input" style="width: 100%;">
          <input matInput placeholder="Name" formControlName="name">
          <mat-error>
            Please provide a name
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width-input" style="width: 100%;">
          <input matInput placeholder="Email" formControlName="username" required>
          <mat-error>
            Please provide a valid email address
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width-input" style="width: 100%;">
          <input matInput type="password" placeholder="Password" formControlName="password" required>
          <mat-error>
            Please provide a valid password
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width-input" style="width: 100%;">
          <input matInput type="password" placeholder="Confirm Password" formControlName="passwordc">
          <mat-error>
            Please provide a valid password confirmation
          </mat-error>
        </mat-form-field>

        <button mat-raised-button color="primary" style="margin-top: 40px;" [disabled]="loading">Sign Up</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
